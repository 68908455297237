<template>
    <div>
        <component :is="inputComponent" v-if="inputComponent"/>
        <component :is="emptyComponent" v-if="emptyComponent && List.length === 0"  />
        <component :is="listComponent" :commentData="List" v-else-if="listComponent"  />
        <component :is="paginationComponent" :paginationData="filterSet.page" :listLength="List.length" v-if="paginationComponent"  />
    </div>
</template>
<script>
    import vuxAlert from "@/mixins/vux-alert";
    import boardTemplateUtil from "@/components/board/boardTemplateUtil"
    import boardFilterUtil from "@/components/board/boardFilterUtil"
    import {mapGetters, mapState} from "vuex";
    import EventBus from "@/utils/event-bus";
    import listMixins from "@/mixins/listMixins";
    import filterMixins from "@/mixins/filterMixins";
    const {filterConfig} = boardFilterUtil;
    import util from "@/mixins/util";

    export default {
        name: 'CommentComponent',
        mixins: [ filterMixins, listMixins, boardTemplateUtil, boardFilterUtil, vuxAlert ],
        components: {},
        provide: function () {
            return {
                commentSetData: this.setData,
                commentWrite: this.commentWrite,
                commentUpdate: this.commentUpdate,
                commentSetPage: this.setPage,
                commentSetFilter: this.setFilter,
                commentDelete: this.commentDelete,
                commentBlind: this.commentBlind,
            }
        },
        //inject: ['afterCommentWriteFunc', 'afterCommentDeleteFunc'],
        inject: {
            afterCommentWriteFunc: {default: () => {}},
            afterCommentDeleteFunc: {default: () => {}},
        },
        props: {
            boardType: {
                type: String,
                default: '',
            },
            boardKey: {
                default: 0,
            },
        },
        data() {
            return{
                commentListLayout: '',
                commentEmptyLayout: '',
                commentInputLayout: '',
                commentPaginationLayout: '',
                listComponent: null,
                emptyComponent: null,
                inputComponent: null,
                paginationComponent: null,
                noData: false,

                getFunc: '',
                listApiParamSet: {},
                pageRow: 10,
                writeFunc: '',
                writeApiParamSet: {},
                updateFunc: '',
                updateApiParamSet: {},
                deleteFunc: '',
                deleteApiParamSet: {},
                blindFunc: '',
                keyName: '',

                commentTxt: '',
                updateCommentData: {},
            }
        },
        created() {
            this.setFilterConfig(filterConfig);
            this.setParserType('url', this.$route.fullPath);
            this.initConfig();
            EventBus.$on('modifyCommentAfterFunc', this.modifyComment)
        },
        mounted() {
            this.loadLayout();
            this.getList(true);
        },
        beforeDestroy() {
            EventBus.$off('modifyCommentAfterFunc')
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
            listLoader() {
                if (!this.commentListLayout) {
                    return null
                }
                return () => import(`@/template/board/comment/${this.commentListLayout}`)
            },
            emptyLoader() {
                if (!this.commentEmptyLayout) {
                    return null
                }
                return () => import(`@/template/board/comment/${this.commentEmptyLayout}`)
            },
            inputLoader() {
                if (!this.commentInputLayout) {
                    return null
                }
                return () => import(`@/template/board/comment/${this.commentInputLayout}`)
            },
            paginationLoader() {
                if (!this.commentPaginationLayout) {
                    return null
                }
                return () => import(`@/template/board/comment/${this.commentPaginationLayout}`)
            },
        },
        methods: {
            setData(key, data) {
                this[key] = data;
            },
            initConfig() {
                let boardTemplateConfigs = this.returnBoardTemplateConfig(this.boardType);
                this.commentListLayout = boardTemplateConfigs.commentListLayout;
                this.commentEmptyLayout = boardTemplateConfigs.commentEmptyLayout;
                this.commentInputLayout = boardTemplateConfigs.commentInputLayout;
                this.commentPaginationLayout = boardTemplateConfigs.commentPaginationLayout;

                let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
                this.getFunc = boardFilterConfig.getFunc;
                this.paginationType = boardFilterConfig.paginationType;
                this.listApiParamSet = boardFilterConfig.listApiParamSet;
                this.listApiParamSet.pa = this.boardKey;
                boardFilterConfig.pageRow && boardFilterConfig.pageRow != this.filterSet.page.size ? this.setPage('size', boardFilterConfig.pageRow) : '';
                this.writeFunc = boardFilterConfig.writeFunc;
                this.writeApiParamSet = boardFilterConfig.writeApiParamSet;
                this.updateFunc = boardFilterConfig.updateFunc;
                this.updateApiParamSet = boardFilterConfig.updateApiParamSet;
                this.deleteFunc = boardFilterConfig.deleteFunc;
                this.deleteApiParamSet = boardFilterConfig.deleteApiParamSet;
                this.blindFunc = boardFilterConfig.blindFunc;
                this.keyName = boardFilterConfig.keyName;
                let listOptions = {
                    url : this.$api.$board[this.getFunc],
                    defaultParams : this.listApiParamSet,
                }
                this.initList('api', listOptions);
            },
            commentWrite() {
                let params = JSON.parse(JSON.stringify(this.writeApiParamSet))
                params.wr_parent = Number(this.boardKey);
                params.wr_content = this.commentTxt;
                this.$api.$board[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    let type = this.returnCommentWriteType(res.Info.type);
                    if(type != true) {
                        this.errorAlert(type);
                        return false;
                    }
                    params.wr_id = res.List;
                    this.addCommentList(params)
                    EventBus.$emit('initCommentText')
                    if(!util.isEmpty(this.afterCommentWriteFunc)) {
                        this.afterCommentWriteFunc();
                    }
                    EventBus.$emit('listCommentCountAdd', this.keyName, Number(this.boardKey));
                })
            },
            addCommentList(paramsObj) {
                let commentObj = JSON.parse(JSON.stringify(paramsObj))
                commentObj.Writer = this.UserInfo;
                commentObj.created_at = this.$moment().format('YYYY-MM-DD HH:mm:ss');
                this.List.unshift(commentObj);
                this.filterSet.page.total = this.filterSet.page.total + 1;
                if(this.paginationType === 'lazyload' && (this.List.length < this.filterSet.page.total)) {
                    this.List.splice(this.List.length - 1, 1);
                }
            },
            returnCommentWriteType(type) {
                switch (type) {
                    case 1:
                        return true;
                    default:
                        return '댓글 등록에 실패하였습니다. 잠시후 시도해주세요.'
                }
            },
            commentUpdate(comment) {
                let params = JSON.parse(JSON.stringify(this.updateApiParamSet));
                params.wr_content = comment.wr_content;
                params.wr_id = comment.wr_id;
                try {
                    this.$api.$board[this.updateFunc](params).then(res => res.Data.Result).then(res => {
                        let type = this.returnCommentUpdateType(res.Info.type);
                        if(type != true) {
                            this.errorAlert(type);
                            return false;
                        }
                        this.createAlert({
                            title: '',
                            content: '등록되었습니다.',
                            btnText: '확인',
                            hide: () => {
                                comment.hasUpdate = false;
                                this.$forceUpdate();
                                EventBus.$emit('commentListLayoutUpdate')
                            }
                        })
                    })
                }catch (e) {
                    console.log(e);
                }
            },
            returnCommentUpdateType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '권한이 없습니다.'
                    case -5:
                        return '금지어가 포함되어있습니다.'
                    case -7:
                        return '댓글이 존재하지 않습니다.'
                    default:
                        return '댓글 수정에 실패하였습니다. 잠시후 다시 시도해주세요.'
                }
            },
            commentDelete(key) {
                let params = JSON.parse(JSON.stringify(this.deleteApiParamSet));
                params[this.keyName] = [key];
                try {
                    this.$api.$board[this.deleteFunc](params).then(res => res.Data.Result).then(res => {
                        let type = this.returnCommentDeleteType(res.Info.type);
                        if(type != true) {
                            this.errorAlert(type);
                            return;
                        }
                        this.createAlert({
                            title: '',
                            content: '삭제가 완료되었습니다.',
                            btnText: '확인',
                        });
                        this.getLastComment(key);
                        if(!util.isEmpty(this.afterCommentDeleteFunc)) {
                            this.afterCommentDeleteFunc();
                        }
                        EventBus.$emit('listCommentCountMinus', this.keyName, Number(this.boardKey))
                    })
                }catch (e) {
                    console.log(e)
                }
            },
            getLastComment(key) {
                let commentIndex = this.List.findIndex(comment => comment[this.keyName] === key);
                if(commentIndex < 0) {
                    return false;
                }
                this.List.splice(commentIndex, 1);
                this.filterSet.page.total = this.filterSet.page.total - 1;
                if(this.paginationType === 'lazyload' && (this.List.length < this.filterSet.page.total - 1)) {
                    let params = JSON.parse(JSON.stringify(this.listApiParamSet));
                    params.pa = this.boardKey;
                    params.page = this.filterSet.page.page * this.pageRow + 1;
                    params.sp = 1;
                    this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
                        if(res.Info.type === 1 && res.List.length > 0) {
                            this.List.push(res.List[0]);
                        }
                    })
                }
            },
            returnCommentDeleteType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '권한이 없습니다.';
                    case -7:
                        return '이미 삭제된 댓글입니다.';
                    default:
                        return '댓글 삭제를 실패하였습니다. 잠시후 시도해주세요.'
                }
            },
            commentBlind(comment, text) {
                let params = {
                    bb_reason: text
                }
                params[this.keyName] = comment[this.keyName];
                try {
                    this.$api.$board[this.blindFunc](params).then(res => res.Data.Result).then(res => {
                        let type = this.returnCommentBlindType(res.Info.type);
                        if(type === true) {
                            this.createAlert({
                                title: '',
                                content: '신고완료',
                                btnText: '확인',
                            })
                        }else{
                            this.errorAlert(type);
                        }
                    })
                }catch (e) {
                    console.log(e)
                }
            },
            returnCommentBlindType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '이미 신고하신 댓글입니다.';
                    case -403:
                    case -404:
                        return '이미 삭제된 댓글입니다.';
                    default:
                        return "신고처리가 실패하였습니다. 잠시 후 다시 시도해주세요.";
                }
            },
            modifyComment(comment) {
                let commentIndex = this.List.findIndex(comment => comment[this.keyName] === comment[this.keyName]);
                if(commentIndex > -1) {
                    this.List[commentIndex] = comment;
                }
            },
            loadLayout() {
                this.listLoader()
                    .then(() => {
                        this.listComponent = () => this.listLoader()
                    })
                    .catch(() => {
                        this.listComponent = () => import('@/template/board/comment/CommentListDefaultLayout')
                    })
                if(this.inputLoader != null) {
                    this.inputLoader()
                        .then(() => {
                            this.inputComponent = () => this.inputLoader()
                        })
                        .catch(() => {
                            this.inputComponent = () => import('@/template/board/comment/CommentInputDefaultLayout')
                        })
                }
                if(this.paginationLoader != null) {
                    this.paginationLoader()
                        .then(() => {
                            this.paginationComponent = () => this.paginationLoader()
                        })
                        .catch(() => {
                            this.paginationComponent = () => import('@/template/board/comment/CommentPaginationtDefaultLayout')
                        })
                }
                if(this.emptyLoader != null) {
                    this.emptyLoader()
                        .then(() => {
                            this.emptyComponent = () => this.emptyLoader()
                        })
                        .catch(() => {
                            this.paginationComponent = () => import('@/template/board/comment/CommentEmptyLayout')
                        })
                }
            },
        },
        watch: {},
    }
</script>